<template>
  <div class="home">

  <div class="header">
    <div class="inforuta">
      <span :style="{marginTop:'0%',marginLeft:'0%' , position:'absolute'}">Go Jul,</span>
      <span :style="{marginTop:'20%',marginLeft:'0%'  ,fontWeight:'bold', position:'absolute'}">{{this.$route.query.person}}</span>
    </div>
    <img  class="header_profilepicture"  :src= "`${publicPath}`+ 'tomte.jpg'" >
  </div>

      <div :style="{height:'90px', marginTop:'8%',marginLeft:'5%',width:'90%', fontSize:'17px' }" >
        Clicka på knappen nedan för att slumpa fram din Secret Santa <br>
        (Med andra ord personen du ska ge en julklapp till)

      </div>
      <div :style="{height:'110px'}">
        <button type="button" @click="slumpa_santa()" :style="{borderRadius:'9px',fontSize:'17px',marginTop:'3%', marginLeft:'10%',backgroundColor:'red',color:'white', height:'35px',borderStyle:'None', width:'70%'}" >
          Slumpa din Secret Santa
        </button>
        <input type="text" :style="{borderRadius:'9px',height:'25px',fontSize:'17px',width:'70%', marginTop:'2%', marginLeft:'10%'}" :value="this.resultat">
      </div>

       <img :style="{height:'270px',marginTop:'3%', marginLeft:'8%',width:'310px'}"  :src= "`${publicPath}`+ 'tomte.jpg'" alt="">

  </div>



</template>

<script>
export default {
  name: 'Home',
  data: function(){
  return{
    publicPath: process.env.BASE_URL +'',
    resultat: "",
    bild:"",
    top_users_list:[
      {person:'Fanny', getill:"Robert" },
      {person:'Jonte', getill:"Amanda" },
      {person:'Anna', getill:"Jonte" },
      {person:'Amanda', getill:"Clara" },
      {person:'Robert', getill:"Anna"},
      {person:'Clara', getill:"Fanny"}
    ],

    top_users_list_2021:[
      {person:'Fanny', getill:"Filippa" },
      {person:'Jonte', getill:"Fanny" },
      {person:'Filippa', getill:"Anna" },
      {person:'Anna', getill:"Amanda" },
      {person:'Amanda', getill:"Robert" },
      {person:'Robert', getill:"Clara"},
      {person:'Clara', getill:"Jonte"}
    ],
    lista2022:[
      {person: "Fanny", kod:"Fanny"},
      {person: "Amanda", kod:"Amanda"},
      {person: "Robert", kod:"Robert"},
      {person:"Anna", kod:"Anna"},
      {person:"Clara", kod:"Clara"},
      {person:"Filippa", kod:"Filippa"},
      {person:"Jonte", kod:"jonte"},
      {person:"Joel", kod:"Joel"}
  ],
  lista2023:[
    {person: "Fanny", kod:"Fanny"},
    {person: "Amanda", kod:"Amanda"},
    {person: "Robert", kod:"Robert"},
    {person:"Anna", kod:"Anna"},
    {person:"Clara", kod:"Clara"},
    {person:"Jonte", kod:"jonte"},
]

  }
},
methods:{
  slumpa_santa(){
    for (let i = 0; i < this.top_users_list.length; i++) {
      if(this.$route.query.person ==this.top_users_list[i]["person"]){
        this.resultat = this.top_users_list[i]["getill"]
        break
      }
     }
    }
  },
  mounted(){

    if (this.$route.query.person =='Fanny'){
            this.bild = 'fanny.jpg'
    }
    else if (this.$route.query.person =='Filippa'){
            this.bild = 'filippa.jpg'
    }
    else if (this.$route.query.person =='Anna'){
            this.bild = 'anna.jpg'
    }
    else if (this.$route.query.person =='Clara'){
            this.bild = 'clara.jpg'
    }
    else if (this.$route.query.person =='Amanda'){
            this.bild = 'amanda.jpg'
    }
    else if (this.$route.query.person =='Robert'){
            this.bild = 'robert.jpg'
    }
    else{
      this.bild = 'tomte.jpg'
    }


  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body{
  font-family: sans-serif;
}

.home{
  height: 1970px;
  width: 100%;
  padding-bottom: 100px;
  font-family: Open Sans;
}


.header{
  display: flex;
  height:124px;
  background-color: red;
  width: 100%;
  margin-left: 0%;
  z-index: 2;
  margin-top: 0%;
}

.inforuta{
  width: 141px;
  height: 102px;
  display: flex;
  position: absolute;
  margin-top:  5%;
  margin-left: 8.5%;
  font-size: 30px;
  color:white;
  cursor: pointer;
}

h3{
  margin-left: 0%;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  margin-top: 0%;
}

.header_profilepicture{
  display: flex;
  position: absolute;
  margin-top: 5.5%;
  height: 72px;
  width: 72px;
  margin-left: 75%;
  border-radius: 24px;
  object-fit: cover;
  border-style: solid;
  border-color: white;
  border-width: 3px;
}


.switch_box_large{
  width: 96%;
  height: 80px;
  margin-top: 0%;
  border-radius: 8px;
}



.container{
  width: 101%;
  /* width: 90%; */
  display: flex;
  overflow-x: scroll;
  margin-left: 0%;
  height: 130px;
  margin-top: 2%;
}


.container_small{
    width:150px;
  margin-top: 1%;

  display: flex;
  height: 116px;
  z-index: 1;
  margin-left: 0%;
}

.accomplishment_toggle_our_box{
  margin-top: 1%;
  display: flex;
  height: 63px;
  width: 47%;
  border-radius: 8px 0px 0px 8px;
  margin-left: 3%;
  z-index: 1;
  object-fit: cover;
  position:absolute;
  background-color: #40C46E;
}

.accomplishment_toggle_our_box_border{
  height: 53px;
  width: 45%;
  margin-left: 2%;
  position:absolute;
  border-style: solid;
  border-color: #707070;
  border-width: 5px;
  border-radius: 8px;
  z-index: 2;
    margin-top: 1%;
}

.accomplishment_toggle_my_box{
  margin-top: 1%;
  display: flex;
  height: 63px;
  width: 47%;
  border-radius: 0px 8px 8px 0px;
  margin-left: 50%;
  z-index: 1;
  object-fit: cover;
  position:absolute;
  background-color: #40C46E;
}

.accomplishment_toggle_my_box_border{
  height: 53px;
  width: 45%;
  margin-left: 50%;
  position:absolute;
  border-style: solid;
  border-color: #707070;
  border-width: 5px;
    border-radius: 8px;
  z-index: 2;
  margin-top: 1%;
}

.accomplishment_toggle_our_box_grey{
  margin-top: 1%;
  display: flex;
  height: 63px;
  width: 47%;
  margin-left: 3%;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  background-color: white;
  opacity: 0.52;

}

.accomplishment_toggle_my_box_grey{
  margin-top: 1%;
  display: flex;
  height: 63px;
  width: 47%;
  margin-left: 50%;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  background-color: white;
  opacity: 0.52;
}

.accomplishment_toggle_our_box_textclass{
  display: flex;
  height: 96px;
  width: 50%;
  z-index: 3;
  margin-left: 0%;
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  color: white;
}

.accomplishment_toggle_my_box_textclass{
  display: flex;
  height: 96px;
  width: 50%;
  z-index: 3;
  margin-left:50%;
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  color: white;
}

.total_impact_class{
  height: 140px;
    /* height: 260px; */
  width: 100%;
  margin-top: -5%;
  border-radius: 8px 8px 8px 8px;
  background-color: white;
}


.box1{
  box-shadow: 0px 3px 30px #0000001A;
  height: 78px;
  width: 43%;
  border-radius: 8px;
  margin-top: 6%;
  margin-left: 4.2%;
  font-size: 24px;
  color:  black;
  background-color:white;
  position: absolute;
}


.box2{
  height: 78px;
  width: 43%;
  border-radius: 8px;
  box-shadow: 0px 3px 30px #0000001A;
  border-radius: 8px;
  margin-top: 6%;
  margin-left: 52%;
  font-size: 24px;
  color:  black;
  background-color:white;
  position: absolute;
}

.box3{
  height: 78px;
  width: 106px;
  border-radius: 8px;
  box-shadow: 0px 3px 30px #0000001A;
  border-radius: 8px;
  margin-top: 6%;
  margin-left: 66%;
  font-size: 24px;
  color:  black;
  background-color:white;
  position: absolute;
}

.group_action_box_large3{
  width: 96%;
  height: 130px;
  margin-top: 0%;
  border-radius: 8px;
}

.container_post2{
  width: 101%;
  display: flex;
  overflow-x: scroll;
  margin-left: 0%;
  height: 130px;
  margin-top: 2%;
}

.group_action_post4{
  min-width:318px;
  position: relative;
  margin-top: 1%;
  border-radius: 8px;
  display: flex;
  height: 116px;
  z-index: 1;
  margin-left: 2%;
  object-fit: cover;
}

.group_action_post4_user{
  min-width:78px;
  position: relative;
  margin-top: 1%;
  border-radius: 24px;
  display: flex;
  height: 78px;
  z-index: 1;
  margin-left: 4%;
  object-fit: cover;
}

.imageclass_grey_2{
  margin-top: 1%;
  display: flex;
  height: 105px;
  width: 100%;
  margin-left: 3%;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  background-color: black;
  opacity: 0.4;
}
.imageclass_1{
  margin-top: 1%;
  display: flex;
  height: 105px;
  width: 100%;
  border-radius: 8px;
  margin-left: 3%;
  z-index: 1;
  object-fit: cover;
  position:absolute;
  background-color: darkblue;
}

.imageclass_1_user{
  margin-top: 1%;
  display: flex;
  height: 78px;
  width: 100%;
  border-radius: 8px;
  margin-left: 3%;
  z-index: 1;
  object-fit: cover;
  position:absolute;
  background-color: darkblue;
}

.textclass_1{
  display: flex;
  height: 96px;
  width: 100%;
  z-index: 3;
  margin-left: 0%;
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  color: white;
}

.box1_together{
  box-shadow: 0px 3px 30px #0000001A;
  height: 48px;
  width: 90px;
  border-radius: 8px;
  margin-top: 9%;
  margin-left: 6%;
  font-size: 18px;
  color:  black;
  /* background-color:white; */
  position: absolute;
}

.box2_together{
  box-shadow: 0px 3px 30px #0000001A;
  height: 48px;
  width: 98px;
  border-radius: 8px;
  margin-top: 9%;
  margin-left: 40%;
  font-size: 17px;
  color:  black;
  /* background-color:white; */
  position: absolute;
}

.box3_together{
  box-shadow: 0px 3px 30px #0000001A;
  height: 48px;
  width: 126px;
  border-radius: 8px;
  margin-top: 9%;
  margin-left: 71%;
  font-size: 17px;
  color:  black;
  /* background-color:white; */
  position: absolute;
}

</style>

<template>
  <div class="home">

  <div class="header">
    <div class="inforuta">
        Välkommen till Cygnaeus Secret Santa Generator
    </div>
  </div>

      <div :style="{height:'150px',marginLeft:'7%',}">

        <input type="text" name="" :style="{marginTop:'5%',borderRadius:'9px', marginLeft:'5%',width:'68%', height:'30px'}" placeholder="Fyll i din kod" value="" v-model="kod">

        <button type="button" @click="check_code_login()" :style="{borderRadius:'9px',fontSize:'17px',marginTop:'3%', marginLeft:'5%',backgroundColor:'red',color:'white', height:'35px',borderStyle:'None', width:'70%'}" >
          Clicka för att logga in
        </button>

      </div>
       <img :style="{height:'270px',marginTop:'3%', marginLeft:'8%',width:'310px'}"  :src= "`${publicPath}`+ 'tomte.jpg'" alt="">

  </div>
</template>

<script>

import router from '../router'
// import {API_URL} from '../config.js'
// import axios from 'axios';
export default {
  name: 'Home',
  data: function(){
  return{
    publicPath: process.env.BASE_URL +'',
    kod:"",
    lista:[
        {person: "Fanny", kod:"Fanny"},
        {person: "Amanda", kod:"Amanda"},
        {person: "Robert", kod:"Robert"},
        {person:"Anna", kod:"Anna"},
        {person:"Clara", kod:"Clara"},
        {person:"Filippa", kod:"Filippa"},
        {person:"Jonte", kod:"jonte"}
    ]
  }
},
methods:{
  check_code_login(){
    for (let i = 0; i < this.lista.length; i++) {
      if(this.kod ==this.lista[i]["kod"]){
        router.push({ path:"secret_santa2", query:{person:this.lista[i]["person"]} }).catch(()=>{})
        break
      }
      else if (i ==(this.lista.length-1)) {
        this.$confirm("Sorry fel kod, try again :)","",'error').then(() => {
        })
      }
    }

    }



},
  mounted(){

    console.log("hh");
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body{
  font-family: sans-serif;
}

.home{
  height: 970px;
  width: 100%;
  padding-bottom: 100px;
  font-family: sans-serif;
}


.header{
  display: flex;
  height:124px;

  background-color: red;
  width: 100%;
  margin-left: 0%;
  z-index: 2;
  margin-top: 0%;
}

.inforuta{
  width: 90%;
  height: 102px;
  display: flex;
  position: absolute;
  margin-top:  5%;
  margin-left: 8.5%;
  font-size: 30px;
  color:white;
  cursor: pointer;
}

h3{
  margin-left: 0%;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  margin-top: 0%;
}

.header_profilepicture{
  display: flex;
  position: absolute;
  margin-top: 5.5%;
  height: 72px;
  width: 72px;
  margin-left: 75%;
  border-radius: 24px;
  object-fit: cover;
  border-style: solid;
  border-color: white;
  border-width: 3px;
}


.switch_box_large{
  width: 96%;
  height: 80px;
  margin-top: 0%;
  border-radius: 8px;
}



.container{
  width: 101%;
  /* width: 90%; */
  display: flex;
  overflow-x: scroll;
  margin-left: 0%;
  height: 130px;
  margin-top: 2%;
}


.container_small{
    width:150px;
  margin-top: 1%;

  display: flex;
  height: 116px;
  z-index: 1;
  margin-left: 0%;
}

.accomplishment_toggle_our_box{
  margin-top: 1%;
  display: flex;
  height: 63px;
  width: 47%;
  border-radius: 8px 0px 0px 8px;
  margin-left: 3%;
  z-index: 1;
  object-fit: cover;
  position:absolute;
  background-color: #40C46E;
}

.accomplishment_toggle_our_box_border{
  height: 53px;
  width: 45%;
  margin-left: 2%;
  position:absolute;
  border-style: solid;
  border-color: #707070;
  border-width: 5px;
  border-radius: 8px;
  z-index: 2;
    margin-top: 1%;
}

.accomplishment_toggle_my_box{
  margin-top: 1%;
  display: flex;
  height: 63px;
  width: 47%;
  border-radius: 0px 8px 8px 0px;
  margin-left: 50%;
  z-index: 1;
  object-fit: cover;
  position:absolute;
  background-color: #40C46E;
}

.accomplishment_toggle_my_box_border{
  height: 53px;
  width: 45%;
  margin-left: 50%;
  position:absolute;
  border-style: solid;
  border-color: #707070;
  border-width: 5px;
    border-radius: 8px;
  z-index: 2;
  margin-top: 1%;
}

.accomplishment_toggle_our_box_grey{
  margin-top: 1%;
  display: flex;
  height: 63px;
  width: 47%;
  margin-left: 3%;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  background-color: white;
  opacity: 0.52;

}

.accomplishment_toggle_my_box_grey{
  margin-top: 1%;
  display: flex;
  height: 63px;
  width: 47%;
  margin-left: 50%;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  background-color: white;
  opacity: 0.52;
}

.accomplishment_toggle_our_box_textclass{
  display: flex;
  height: 96px;
  width: 50%;
  z-index: 3;
  margin-left: 0%;
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  color: white;
}

.accomplishment_toggle_my_box_textclass{
  display: flex;
  height: 96px;
  width: 50%;
  z-index: 3;
  margin-left:50%;
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  color: white;
}

.total_impact_class{
  height: 140px;
    /* height: 260px; */
  width: 100%;
  margin-top: -5%;
  border-radius: 8px 8px 8px 8px;
  background-color: white;
}


.box1{
  box-shadow: 0px 3px 30px #0000001A;
  height: 78px;
  width: 43%;
  border-radius: 8px;
  margin-top: 6%;
  margin-left: 4.2%;
  font-size: 24px;
  color:  black;
  background-color:white;
  position: absolute;
}


.box2{
  height: 78px;
  width: 43%;
  border-radius: 8px;
  box-shadow: 0px 3px 30px #0000001A;
  border-radius: 8px;
  margin-top: 6%;
  margin-left: 52%;
  font-size: 24px;
  color:  black;
  background-color:white;
  position: absolute;
}

.box3{
  height: 78px;
  width: 106px;
  border-radius: 8px;
  box-shadow: 0px 3px 30px #0000001A;
  border-radius: 8px;
  margin-top: 6%;
  margin-left: 66%;
  font-size: 24px;
  color:  black;
  background-color:white;
  position: absolute;
}

.group_action_box_large3{
  width: 96%;
  height: 130px;
  margin-top: 0%;
  border-radius: 8px;
}

.container_post2{
  width: 101%;
  display: flex;
  overflow-x: scroll;
  margin-left: 0%;
  height: 130px;
  margin-top: 2%;
}

.group_action_post4{
  min-width:318px;
  position: relative;
  margin-top: 1%;
  border-radius: 8px;
  display: flex;
  height: 116px;
  z-index: 1;
  margin-left: 2%;
  object-fit: cover;
}

.group_action_post4_user{
  min-width:78px;
  position: relative;
  margin-top: 1%;
  border-radius: 24px;
  display: flex;
  height: 78px;
  z-index: 1;
  margin-left: 4%;
  object-fit: cover;
}

.imageclass_grey_2{
  margin-top: 1%;
  display: flex;
  height: 105px;
  width: 100%;
  margin-left: 3%;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  background-color: black;
  opacity: 0.4;
}
.imageclass_1{
  margin-top: 1%;
  display: flex;
  height: 105px;
  width: 100%;
  border-radius: 8px;
  margin-left: 3%;
  z-index: 1;
  object-fit: cover;
  position:absolute;
  background-color: darkblue;
}

.imageclass_1_user{
  margin-top: 1%;
  display: flex;
  height: 78px;
  width: 100%;
  border-radius: 8px;
  margin-left: 3%;
  z-index: 1;
  object-fit: cover;
  position:absolute;
  background-color: darkblue;
}

.textclass_1{
  display: flex;
  height: 96px;
  width: 100%;
  z-index: 3;
  margin-left: 0%;
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  color: white;
}

.box1_together{
  box-shadow: 0px 3px 30px #0000001A;
  height: 48px;
  width: 90px;
  border-radius: 8px;
  margin-top: 9%;
  margin-left: 6%;
  font-size: 18px;
  color:  black;
  /* background-color:white; */
  position: absolute;
}

.box2_together{
  box-shadow: 0px 3px 30px #0000001A;
  height: 48px;
  width: 98px;
  border-radius: 8px;
  margin-top: 9%;
  margin-left: 40%;
  font-size: 17px;
  color:  black;
  /* background-color:white; */
  position: absolute;
}

.box3_together{
  box-shadow: 0px 3px 30px #0000001A;
  height: 48px;
  width: 126px;
  border-radius: 8px;
  margin-top: 9%;
  margin-left: 71%;
  font-size: 17px;
  color:  black;
  /* background-color:white; */
  position: absolute;
}

</style>

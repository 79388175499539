<template lang="html">
  <div class="">
    <div :style="{height:'auto',display:'flex',}" >

     <!-- <div class="our_services_header">
        <svg class="svg"  viewBox="0 0 471 1164" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M92.6853 586.773C92.6853 586.773 451 1603.5 -568 937C-735.135 842.314 -606.084 408.958 -512.565 243.884C-419.046 78.8093 -497.855 -57.5753 -88.8224 26.4664C1052.5 95.0001 92.6853 586.773 92.6853 586.773Z" fill="#F0FFF6"/>
        </svg>
      </div> -->
      <div class="our_services" >
        <div :style="{flexWrap:'wrap',display:'flex', marginLeft:'5%'}">
          <div v-for="(service,id) in service_list" :key="id" class="our_services_forloop">
              <div :style="{textAlign:'center'}">
                <img :src= "`${publicPath}`+ service.image" alt=""  class="our_services_img">

                <!--
                <b-icon  icon="geo-alt-fill" :style="{height:'61px', marginTop:'2%',color:'#0A6338',width:'61px'}"></b-icon>
                <b-icon v-if="service.id ==2 " icon="search" :style="{height:'61px', marginTop:'2%',color:'#0A6338',width:'61px'}"></b-icon>
                <b-icon v-if="service.id ==3 " icon="tools" :style="{height:'61px', marginTop:'2%',color:'#0A6338',width:'61px'}"></b-icon>
                <b-icon v-if="service.id ==4 " icon="arrow-up-square-fill" :style="{height:'61px', marginTop:'2%',color:'#0A6338',width:'61px'}"></b-icon>
                -->
              </div>
              <div :style="{borderRadius: '4px',width:'100%', textAlign:'center',fontSize: '18px', color: '#264653',fontWeight:600}">
                {{service.service_header}}
              </div>
              <div :style="{borderRadius: '4px',width:'95%',textAlign:'center', fontSize: '15px',fontWeight: 400,marginLeft:'2%', }">
                {{service.service}}
              </div>
          </div>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data:function(){
    return{
        service_list:[],
        publicPath: process.env.BASE_URL +'',

    }
 },
 mounted(){
   this.service_list=[
     {'id':1, "image":"lan.png" , 'service_header':'VÄLJ DET LÄN DU BOR I',  'service':'Hitta professionella reparatörer i ditt område genom att välja det län där du bor.','img':'repariflat.jpg' },
     {'id':2, "image":"sök.png" , 'service_header':'SÖK DET DU BEHÖVER LAGA',  'service':'Sök efter det du behöver laga på vår plattform. Vi erbjuder en omfattande lista över reparationstjänster som täcker allt från elektronik till skrädderi.','img':'repariflat.jpg' },
     {'id':3, "image":"see.png", 'service_header':'HITTA REPARATÖR',  'service':'Vårt nätverk av erfarna och skickliga reparatörer finns tillgängliga för att ta itu med dina problem.','img':'repariflat.jpg' },
     // {'id':4, "image":"tool.png", 'service_header':'JAG VILL REPARERA',  'service':'Hittar du inte det du letar efter? Skicka en förfrågan genom att klicka på "Jag vill reparera.." och fyll i formuläret så hittar vi reparatörer till dig.' }
   ]

 }
}
</script>

<style lang="css" scoped>

.our_services{
  flex-wrap: wrap;
  text-align: center;
  width:80%;
  margin-left: 10%;
  margin-top: 0%;
  z-index: 4
}

.our_services_header{
  color: #0A6338;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  width:50%;
  height: 700px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  line-height: 700px
}

.our_services_forloop{
  border-radius: 11px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  margin-top: 3%;
  margin-left: 3%;
  box-shadow: 0px 4px 42px 0px rgba(0, 0, 0, 0.06);
  max-width: 310px;
  height:299px;
}

.our_services_img{
  height:170px;
  margin-top:2%;
  color:#0A6338;
  width:300px
}

.svg{
  position: absolute;
  margin-left: -5%;
  width: 384.257px;
  height: 387.021px;
  transform: rotate(29.533deg);
  flex-shrink: 0;
  fill: #F0FFF6;
}
</style>

<template lang="html">
  <div class="main">

    <!-- datorn -->
    <div class="goal_computer">
      <div :style="{marginTop:'2%', marginLeft:'5%'}">
        <div :style="{paddingTop:'3%',textAlign:'left',color:'white', fontSize:'28px', fontWeight:500}">
          Vårt Mål
        </div>
        <div :style="{ marginTop:'0%', textAlign:'left'}">
          <div :style="{maxWidth: '500px', margin: '0 auto', fontSize: '18px', fontWeight: 400, color: 'white'}" id="goal_text">
           Vårt mål är att göra det enklare och billigare att reparera sina produkter genom att koppla samman dig med kvalificerade reparatörer som kan förlänga livslängden på dina produkter.<br>
           Vi har reparatörer av alla slag från skräddare, skomakare, vitvarureparatörer, möbeltapetserare, IT-reparatörer, urmakare och allt däremellan.
          </div>
        </div>
      </div>

        <img class="image" :src= "`${publicPath}`+ 'recycle.png'" alt="">

    </div>

    <div class="goal_mobile" >
        <div :style="{textAlign:'center',paddingTop:'5%' ,fontSize:'25px', color: 'white',fontWeight:500}">
          Vårt Mål
        </div>
         <div :style="{width: '95%',fontSize:'16px',fontWeight:400,marginTop:'2%',  color: 'white', marginLeft:'2%',height: '250px'}">
           Vårt mål är att göra det enklare och billigare att reparera sina produkter genom att koppla samman dig med kvalificerade reparatörer som kan förlänga livslängden på dina produkter.<br>
           Vi har reparatörer av alla slag från skräddare, skomakare, vitvarureparatörer, möbeltapetserare, IT-reparatörer, urmakare och allt däremellan.<br>
           <!-- Nu finns det även möjlighet att i centrala Stockholms sälja diverse produkter. -->
        </div>
    </div>


  </div>
</template>

<script>
export default {
  data:function(){
    return{
      publicPath: process.env.BASE_URL +'',

    }
  }

}
</script>

<style lang="css" scoped>
@media (max-width:450px ){
  .goal_mobile{
    margin-left: 2%;
    width:96%;
    margin-top: 11%;
    background-color: #0A6338;

  }

  .goal_computer{
    display: none
  }

}

@media (min-width:450px ){
.image{
  width: 232px;
height: 217px;
margin-top: 3%;
margin-left: 30%;
flex-shrink: 0;
}


  .goal_mobile{
    display: none
  }

  .goal_computer{
    height: 320px;
    width: 100%;
    background-color: #0A6338;
    display: flex;
    text-align: center;
    padding-bottom:40px;
  }


}


</style>

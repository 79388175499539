var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{style:({height:'60px', width:'62%', marginLeft:'20%', marginTop:'0%'})},[_c('div',{staticClass:"search_class"},[(!_vm.lan_chosen)?_c('button',{style:({borderStyle:'none',borderRadius:'3px 0px 0px 3px',
        backgroundColor:'#0A6338', boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
        width:'220px',color:'white', fontSize:'18px'}),attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.open_lan()}}},[_vm._v(" Vilket län bor du i? "),_c('b-icon',{attrs:{"icon":"caret-down"}})],1):_vm._e(),(_vm.lan_chosen)?_c('button',{style:({borderStyle:'none',boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)', fontColor:'#8A8A8A',borderRadius:'3px 0px 0px 3px', backgroundColor:'white',fontSize:'18px',color:'#0A6338', width:'220px' }),attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.open_lan()}}},[_vm._v(_vm._s(this.lan_name)+" "),_c('b-icon',{attrs:{"icon":"caret-down"}})],1):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_item),expression:"search_item"}],style:({color:'black',marginLeft:'0.9%',boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)' ,paddingLeft:'1%',border:'none',
              borderRadius:'0px 3px 3px 0px',  width:'88%',
                      height:'100%',fontSize:'18px',marginTop:'0%'}),attrs:{"type":"text","id":"1","name":"input","placeholder":"Vad behöver du laga/fixa? ","value":""},domProps:{"value":(_vm.search_item)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnter.apply(null, arguments)},function($event){return _vm.get_company_search(_vm.search_item)}],"input":function($event){if($event.target.composing)return;_vm.search_item=$event.target.value}}}),(this.search_item_flag==1)?_c('b-icon',{style:({ width:'15px', marginLeft:'60%',marginTop:'1.2%', position:'absolute',cursor:'pointer'}),attrs:{"icon":"x-lg"},on:{"click":function($event){return _vm.open_search()}}}):_vm._e()],1),(this.län_opened)?_c('div',{style:({height:'170px',border:0, position:'absolute',
          borderWidth:'0', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',
          borderRadius:'0px 3px 3px 0px',marginTop:'0%',overflowY:'auto',
          borderColor:'black',
          opacity:'100%',zIndex:13, width:'15%',marginTop:'0.5%',
           backgroundColor:'white',marginLeft:'0%'})},_vm._l((_vm.län_list),function(län,idx){return _c('div',{key:idx,style:({marginLeft:'4%'}),on:{"click":function($event){_vm.choose_län_func(län.id,län.lan)}}},[_c('div',{style:({height:'26px',fontSize:'18px', display:'flex',marginTop:'1%'})},[_c('div',{style:({marginLeft:'1%',marginTop:'0.5%', color:'black'})},[_vm._v(" "+_vm._s(län.lan)+" ")])])])}),0):_vm._e()]),(this.search_item_flag==1)?_c('div',{style:({height:'180px',border:0, position:'absolute',borderWidth:'0',  boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',borderRadius:'26px',marginTop:'0%',  opacity:'100%',zIndex:13, backgroundColor:'white',width:'47%', marginLeft:'35%'})},[(_vm.search_list.length ==0 )?_c('div',[_vm._v(" Tyvärr hittade vi inga resultat denna gången men skicka gärna en förfrågan så ska vi försöka hjälpa dig ")]):_vm._e(),(_vm.search_list.length !=0 )?_c('div',{style:({height:'180px',overflowY:'auto'})},_vm._l((_vm.search_list),function(search,idx){return _c('div',{key:idx,style:({marginLeft:'1%',textAlign:'left',  width:'99%',height:'22px', fontSize:'20px', fontWeight:'bold', marginTop:'1%'})},[_c('div',{style:({width:'100%',height:'22px', display:'flex',marginTop:'1%'}),on:{"click":function($event){return _vm.to_category(search.id,search.category)}}},[_c('div',{style:({marginLeft:'1%',marginTop:'0.5%',fontSize:'18px',fontWeight:500, color:'black'})},[_vm._v(" "+_vm._s(search.category)+" ")])])])}),0):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="html">
  <div class="">
    <!-- <div :style="{textDecoration:'underline', textAlign:'center',fontSize:'25px', fontWeight:500}">
      Våra tjänster
    </div> -->
    <div :style="{marginLeft:'1%', marginTop:'7%'}">
      <div v-for="(service,id) in service_list_small" :key="id" :style="{borderRadius: '11px',
             marginLeft:'2%',width:'96%',background: '#FFF',marginTop:'5%',padding:'5px' ,
           border: '1px solid rgba(0, 0, 0, 0.10)',
             boxShadow: '0px 4px 42px 0px rgba(0, 0, 0, 0.06)', paddingBottom:'8px' }">
          <div :style="{margin:'0 auto', height: '130px',marginTop:'2%'}">
            <img :src= "`${publicPath}`+ service.image" alt=""  class="our_services_img">

            <!-- <b-icon v-if="service.id ==1 " icon="person-fill" :style="{height:'61px', marginTop:'4%',color:'#8e37ff',width:'61px'}"></b-icon> -->
            <!-- <b-icon v-if="service.id ==1 " icon="tools" :style="{height:'61px', marginTop:'4%',color:'#0A6338',width:'61px'}"></b-icon>
            <b-icon v-if="service.id ==2 " icon="search" :style="{height:'61px', marginTop:'4%',textAlign:'center',color:'#0A6338',width:'61px'}"></b-icon> -->
          </div>
          <div :style="{borderRadius: '4px',width:'100%', fontSize: '18px', color: '#264653',fontWeight:600}">
            {{service.service_header}}
          </div>
          <div  v-if="service.id ==2" :style="{borderRadius: '4px',width:'95%', fontSize: '16px',fontWeight: 400,marginLeft:'2%', }">
            {{service.service}}
          </div>
          <div  v-if="service.id ==1" :style="{borderRadius: '4px',width:'95%', fontSize: '16px',fontWeight: 400,marginLeft:'2%', }">
            {{service.service}}
            <b-icon  icon="arrow-up-square-fill" class="our_services_icon" ></b-icon>
            {{text_part2}}
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data:function(){
    return{
        service_list_small:[],
        publicPath: process.env.BASE_URL +'',
        text_part2:""
    }
},
mounted(){

  this.service_list_small=[
    // {'id':1, "image":"tool.png", 'service_header':'JAG VILL REPARERA',  'service':'Vet du inte var du ska börja? Skicka en förfrågan genom att klicka på' },
    // {'id':1, 'service_header':'JAG VILL SÄLJA..',  'service':'Bor du i centrala Stockholm och vill sälja/donera din trasiga produkt? Klicka på "Jag vill sälja.." och fyll i formuläret så skickar vi det vidare till en reparatör.' },
    {'id':2, "image":"see.png",'service_header':'HITTA REPARATÖR ',  'service':'Använd sökfältet ovan för att hitta en reparatör som kan hjälpa dig' },
  ]
  this.text_part2 = "och fyll i formuläret så hittar vi reparatörer till dig."

 }
}
</script>

<style lang="css" scoped>
.our_services_img{
  height:120px;
  margin-top:2%;
  color:#0A6338;
  width:145px
}

.our_services_icon{
  height:20px;
  /* margin-top: 4%;
  text-align: center; */
  color:#0A6338;
  width:20px;
}

</style>

<template lang="html">
  <div class="">
    <div class="main">
        <div class="ninehundred" >
          Välj bland 900 reparatörer i vår plattform
        </div>

         <div class="info_dator">
           Är du reparatör och vill finnas på enjord?<br>
           Registrera dig
           <a  href='https://www.enjord.com/register' :style="{ color:'black', textDecoration:'underline'}"  >
             här
           </a> eller maila oss på info@enjord.com så sätter vi upp ett möte.<br>
           Har du redan ett konto? Logga in
           <a  href='https://www.enjord.com/login_signup' :style="{ color:'black', textDecoration:'underline'}"  >
             här
           </a>
        </div>
        <div class="info_mobil">
          Är du reparatör och vill finnas på enjord?<br>
          Registrera dig <a  href='https://www.enjord.com/register' :style="{ color:'black', textDecoration:'underline'}"  >
            här
          </a>  eller maila oss på info@enjord.com så sätter vi upp ett möte.<br>
          Har du redan ett konto? <br>Logga in    <a  href='https://www.enjord.com/login_signup' :style="{ color:'black', textDecoration:'underline'}"  >
               här
             </a>
       </div>

    </div>

  </div>
</template>

<script>
export default {



}
</script>

<style lang="css" scoped>

  @media (max-width:450px ){
    .main{
      margin-left: 2%;
      font-size: 16px;
      width:96%;
      margin-top: 2%;
    }

    .ninehundred{
        color: #0A6338;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;


        font-size: 22px;
        /* line-height: 32px; */
        text-align: center;
        margin-left: 2%;
        width:96%;
        height:100px;
        margin-top: 7%;
        padding-top: 2%;
    }

    .info_dator{
      display: none;
    }
    .info_mobil{
      width: 95%;
      font-weight: 400;
      color: #264653;
      margin-left:2%;
      height: 160px;

    }

  }

  @media (min-width:450px ){
    .main{
      margin-left: 2%;
      font-size: 25px;
      margin-top: 3%;
      width:96%;
    }

    .ninehundred{
      color: #0A6338;
      font-family: sans-serif;
      font-size: 38px;
      font-style: normal;
      font-weight: 600;
      line-height: 65px;
    }
    .info_mobil{
      display: none;
    }

    .info_dator{
      color: #000;
      text-align: center;
      font-family: sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      height: 160px;
      line-height: 143.023%;
    }

  }


</style>

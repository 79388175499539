<template lang="html">
  <div class="">
    <div class="open_gear_box_outside" @click="open_contact_form()" v-if="this.checked_contact_form">
    </div>

    <contactformGeneral  v-if="checked_contact_form" :company_id="company_id" :verified="verified"  from_page="home"/>

    <div  class="main" >
      <div class="main_subdiv" >
            <div class="main_subdiv_header"  >
              Sveriges reparatörsplattform
              <!-- Sälj din trasiga produkt -->
            </div>
            <div class="main_subdiv_header_ingress"  >
              <h1  class="main_subdiv_header_ingress_h1">
                  <!-- Vi håller just nu på med ett betatest i centrala Stockholm, berätta vad du vill sälja så kontaktar vi dig -->
                  Sök produkt du vill laga och hitta reparatören för dig
                  <!-- Berätta vad du vill laga så ser vi till att en reparatör kontaktar dig -->
              </h1>
            </div>

            <div class="main_subdiv_bullet_main_computer"  >
              <div class="main_subdiv_bullet_main_forloop"
              v-for="(bullet_point,idx) in bullet_point_div"  :key="idx">
                  <!-- <b-icon icon="check-circle-fill" class="main_subdiv_bullet_main_icon" /> -->
                  <img :src= "`${publicPath}`+ 'tick.png' " alt="" class="main_subdiv_bullet_main_icon">
                  <h2 class="main_subdiv_bullet_main_bullet" >
                      {{bullet_point.bullet_point}}
                  </h2>
              </div>
            </div>


            <searchComputer class="searchComputer" />
            <Search class="searchmobile" />


            <div class="main_subdiv_bullet_main_mobile"  >
              <div class="main_subdiv_bullet_main_forloop"
                   v-for="(bullet_point,idx) in bullet_point_div"
                   :key="idx" >
                  <img :src= "`${publicPath}`+ 'tick.png' " alt="" class="main_subdiv_bullet_main_icon" >
                  <h2 class="main_subdiv_bullet_main_bullet" >
                      {{bullet_point.bullet_point}}
                  </h2>
              </div>
            </div>


              <!--
                   <div  class="main_subdiv_WantToRepair_main_mobile"   >
                     <button  type="button" name="button" @click="open_contact_form(0,'')"
                            class="main_subdiv_WantToRepair_button">
                       Jag vill reparera...
                     </button>
                   </div>
              -->


           </div>

           <div class="articles_header_kat">
              Våra mest populära kategorier
           </div>

           <div class="main_subdiv_WantToRepair_main"  >
             <div class="main_subdiv_WantToRepair_main_forloop"
                   v-for="(want_to_repair,idx) in want_to_repair_div"  :key="idx"
                   @click="open_link(want_to_repair.link)">
                   <img :src= "`${publicPath}`+ want_to_repair.image "
                     alt="" class="main_subdiv_WantToRepair_img" >
                 <h2 class="main_subdiv_WantToRepair_text" >
                     {{want_to_repair.want_to_repair}}
                 </h2>
             </div>
           </div>

           <div class="articles_header_kat">
              Våra tjänster
           </div>


            <div :style="{height:'auto',width:'100%',backgroundColor:'white'}">
              <div :style="{height:'auto'}" >
                <ourServicesMobile class="ourServicesMobile" />
              </div>
              <div :style="{height:'auto'}" >
                <ourServicesComputer class="ourServicesComputer" />
              </div>
              <ourGoals  />
              <explorePageToRepairers  />
            </div>

            <div class="our_repairers_header">
              Några av våra reparatörer
            </div>

            <div class="our_repairers">
              <div class="main_subdiv_our_repairers_main_forloop"
                      v-for="(our_repairer,idx) in our_repairers"  :key="idx">
                  <img :src= "`${publicPath}`+ our_repairer.image "
                       alt="" class="main_subdiv_our_repair_img" >
                  <h2 class="main_subdiv_our_repair_text" @click="to_website(our_repairer.website)">
                      {{our_repairer.our_repairer}}
                  </h2>
              </div>
            </div>

            <div class="articles_header">
              Nyheter
            </div>
            <div class="news_div">
              <div class="main_subdiv_articles_main_forloop"
                      v-for="(news,idx) in news"  :key="idx"
                      @click="to_news(news.id)" >
                  <div class="main_subdiv_articles_img">
                    <img :src= "`${publicPath}`+ news.image "
                      alt=""  :style="{height:'100%', width:'100%'}">
                  </div>
                  <div class="main_subdiv_articles_text">
                      {{news.news}}
                  </div>
              </div>
            </div>

            <div class="articles_header_news">
              Intervjuer med våra reparatörer
            </div>

            <div class="articles">
              <div class="main_subdiv_articles_main_forloop"
                      v-for="(article,idx) in articles"  :key="idx">
                <img :src= "`${publicPath}`+ article.image "
                  alt="" class="main_subdiv_articles_img" >
                  <h2 class="main_subdiv_articles_text"
                      @click="to_article(article.id)">
                      {{article.company_name}}
                  </h2>
              </div>
            </div>

            <div class="articles_header">
              Saknar du en reparatör på Enjord?
            </div>
            <div class="new_repair_company">

              <div  class="new_repair_company_titel">
                Skriv in deras kontaktuppgifter i fältet nedan så kontaktar vi dem
              </div>

                <div class="new_repair_company_box" >
                    <input type="text" name="" value="" v-model="repaircompany_suggestion"
                      class="new_repair_company_input"   >
                  <button :style="{marginTop:'0%', color:'white' ,marginLeft:'2%', borderStyle:'None', borderRadius:'8px',backgroundColor: '#0A6338'}"
                      @click="send_repairer_tip()" type="button" name="button">
                      Skicka
                  </button>
                </div>
            </div>
          </div>

      <bottombar/>

  </div>
</template>

<script>
import axios from 'axios';
import {API_URL} from '../../config.js'

  //import Navigation from '../components/Navigation.vue';
import Search from '../components/search.vue';
import contactformGeneral from '../components/contact_form_general_request.vue';
 import router from '../router/index'
import ourServicesMobile from '@/components_small/our_services_mobile.vue'
import ourServicesComputer from '@/components_small/our_services_computer.vue'

import explorePageToRepairers from '@/components_small/explore_page_to_repairers.vue'

import searchComputer from '@/components/search_computer.vue'
import ourGoals from '@/components_small/explore_page_our_goals.vue'


import {bus} from '../main'
import bottombar from '../components/bottombar.vue';

export default {
  metaInfo: {
  title: 'Hitta reparatörer för dig',
  titleTemplate: '%s ',
  htmlAttrs: {
    lang: 'sv'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'description', content: 'Hitta reparatörer för dig.' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' }
  ]
},
  data:function(){
    return{
      publicPath: process.env.BASE_URL +'',
      search_opened:false,
      repaircompany_suggestion:'',
      bullet_point_div:[],
      checked_contact_form:false,
      company_id:0,
      verified:0,
      news:[],
      our_repairers:[],
      articles:[],
      want_to_repair_div:[]

    }
  },
  components:{
   bottombar, contactformGeneral,ourServicesMobile,ourServicesComputer,
   explorePageToRepairers,searchComputer,ourGoals, Search
 },
  methods:{
    to_news(id){
       router.push({ path:"news", query:{id:id} })
    },

    to_article(id){
       router.push({ path:"artiklar", query:{id:id} })
      //router.push({ path:"artiklar"}).catch(()=>{})
//, query:{id:id}
    },
    open_link(website){

      window.open(website, '_blank').focus();

    },
    to_website(website){
        window.open(website, '_blank').focus();
    },
    send_repairer_tip(){
      var self= this

      axios({
        method:'post',
        url: API_URL+ '/suggest_repair_company',
        data:{"repaircompany_suggestion":this.repaircompany_suggestion}
      })
      .then(function(){
        self.$alert(" Tack för ditt förslag ")
        self.repaircompany_suggestion=''
       })

    },

    open_contact_form(category_id, category){
      if(this.checked_contact_form){
        this.checked_contact_form=false
      }
      else {
        this.$store.commit('set_category_explore_page_general_req_contact_form', {"category":category, "category_id":category_id})
        this.checked_contact_form=true

        // axios({
        //   method:'post',
        //   url: API_URL+ '/gar_det_att_laga',
        //   data:{}
        // })
        // .then(function(){
        //  })

      }
        this.verified=false
        this.company_id=0
    }
  },
  mounted(){
   bus.$on('close_contact_form', (val) => {
     this.checked_contact_form = val
   })


   this.our_repairers =[
     {"id":41,"our_repairer": "Repamera", "image":"repamera.png","website":"https://www.enjord.com/company_page?id=943"},
     {"id":1,"our_repairer": "Spågan", "image":"spagan.png","website":"https://www.enjord.com/company_page?id=3003"},
     {"id":65,"our_repairer": "Karin Falk Dreier textilkonservering",  "image":"karinfalk.png","website":"https://www.enjord.com/company_page?id=3917"},
     {"id":65,"our_repairer": "WoodNSteel",  "image":"wood.png","website":"https://www.enjord.com/company_page?id=3884"},
     {"id":65,"our_repairer": "Compucraft",  "image":"compucraft.png","website":"https://www.enjord.com/company_page?id=3882"},
     {"id":65,"our_repairer": "Refactory",  "image":"refactory.png","website":"https://www.enjord.com/company_page?id=3883"},
     {"id":65,"our_repairer": "Dr Bike",  "image":"drbike.png","website":"https://www.enjord.com/company_page?id=1876"}
   ]

   this.articles =[
     {"id":0,"company_name": "Spågan", "image":"spagan_art.png"},
     {"id":1,"company_name": "Karin Falk Dreier textilkonservering", "image":"karinfalk.png"},
   ]

   this.news =[
     {"id":0,"news": "Right to repair", "image":"rightorepair.jpg"},
     {"id":1,"news": "Begränsar överförsäljning", "image":"waste.jpg"},
   ]

//https://www.ifixit.com/News/78326/repair-could-save-vintage-video-games


   this.bullet_point_div =[
     {"id":0, "bullet_point":"Ge produkter ett nytt liv"},
     {"id":1, "bullet_point":"Minska miljöpåverkan"},
     {"id":2, "bullet_point":"Stötta lokala reparatörer"}
   ]

   this.want_to_repair_div=[
     {"id":41,"category": "Kläder","want_to_repair":"Skräddare", "image":"fashion.png", "link":"https://www.enjord.com/result_page?category_id=41&from_page=category&category_name=Kl%C3%A4der%2FSkr%C3%A4dderi&lan_id=0&lan="},
     {"id":1,"category": "Dator","want_to_repair":"Dator", "image":"computer.png","link":"https://www.enjord.com/result_page?category_id=1&from_page=category&category_name=Dator"},
     {"id":65,"category": "Dammsugare", "want_to_repair":"Dammsugare", "image":"dammsugare.png", "link":"https://www.enjord.com/result_page?category_id=65&from_page=category&category_name=Dammsugare"},
     {"id":42,"category": "Skor","want_to_repair":"Skor", "image":"shoe.png", "link":"https://www.enjord.com/result_page?category_id=42&from_page=category&category_name=Skor"},
     {"id":1,"want_to_repair":"Telefon", "image":"phone.png", "link":"https://www.enjord.com/result_page?category_id=3&from_page=category&category_name=Telefon%2FMobil%2FSmartphone&lan_id=0&lan="},
     {"id":43,"category": "Möbler","want_to_repair":"Möbler", "image":"furniture.png", "link":"https://www.enjord.com/result_page?category_id=43&from_page=category&category_name=M%C3%B6bler&lan_id=0&lan="}
     // {"id":0,"category": "", "want_to_repair":"Generell", "image":"general.png"},
   ]


  }
}
</script>

<style lang="css" scoped>
input:focus {outline:none!important;}

@media (max-width:450px ){

  .Navigation_large{
      display: None
  }

  .main{
    width:100%
  }

  .main_subdiv{
    width:100%;
    margin-top: 9%;
     height:420px;
    color:#0A6338

  }

  .main_subdiv_header{

    /* color: #0A6338;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 33px;
    font-style: normal;
    font-weight: 900;
    line-height: 108px; /* 207.692%
    text-transform: uppercase; */

    font-size: 35px;
    line-height: 32px;
    text-align: center;
    width:100%;
    color:#0A6338;
    font-weight: 500;
    height:auto;
    margin-top: 0%;
    padding-top: 2%;
   }


  .our_repairers_header{
      display: none;
  }


  .main_subdiv_header_ingress{
    display:flex;
    margin-top: 5%
  }

.main_subdiv_header_ingress_h1{

  /* color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130.5%; */

   line-height: 18px;
   width:85%;
   margin-left: 6.5%;
   text-align: center;
   font-size: 21px;
   margin-top: 3%;
   letter-spacing:  0px;
  }


  .main_subdiv_bullet_main_computer{
    display: none
  }


  .main_subdiv_bullet_main_mobile{
    height:90px;
    margin-top: 14%;
    /* margin-left: 20%; */
    text-align: center;
    align-items:  center;
    justify-content:  center;
    width:90%;
    margin: 0 auto;

  }



  .main_subdiv_bullet_main_forloop{
    display:flex;
    width:100%;
    text-align: center;
    align-items:  center;
    justify-content:  center;
    margin: 0 auto;
  }

  .main_subdiv_bullet_main_icon{
    color:#0A6338;
     margin-top: -3.5%;
     height: 20px;
     width: 20px;

   }

   .searchmobile{
     padding-bottom: 40px;
   }

   .main_subdiv_bullet_main_bullet{
     color:black;
     margin-left: 1%;
     font-size: 17px;
   }

   .main_subdiv_WantToRepair_main{
     width:93%;
     /* margin-left: 6%; */
     text-align: center;
     display: flex;
     margin-top: 0%;
     height:auto;
     flex-wrap:wrap;
     margin: 0 auto;
     justify-content: center;
   }

   .main_subdiv_WantToRepair_main_forloop{
     border-radius: 12px;
     border: 1px solid #D1D1D1;
     background: #FFF;
     box-shadow: 0px 4px 78px 0px rgba(0, 0, 0, 0.06);
     min-width: 128px;
     max-width: 128px;
     height: 135px;
     margin-top: 3%;
     margin-left: 4%;
   }

   .main_subdiv_WantToRepair_img{
     width: 90px;
     height: 90px;
     margin-top: 6%;
   }

   .main_subdiv_WantToRepair_text{
     color: #012333;
     font-size: 20px;
     font-style: normal;
     font-weight: 700;
   }

  .main_subdiv_WantToRepair_button{
    border-style: none;
    border-radius: 8px;
    height:53px;
    background-color: #0A6338;
    padding: 18px 32px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color:white
  }

  .news_div{
    background-color: #F2FFFC ;
    text-align: center;
    align-items: center;
    height: 200px;
    width: 100%;
    display: flex;
    margin-top: -5%;
  }

  .articles{
      text-align: center;
      align-items: center;
      height: 200px;
      width: 100%;
      display: flex;
  }

  .new_repair_company{
    height: 200px;
    width: 100%;
    margin-top: 0%;
    text-align: center;
    align-items: center;
    background-color: #F2FFFC ;

  }

  .new_repair_company_titel{
    margin-left:8%;
    text-align:left;
  }

  .new_repair_company_box {
    width:100%;
    text-Align:center;
    margin-Left:0%;
    display:flex;
  }


  .new_repair_company_input {
    height:30px;
    width:100%;
    margin-Left:0%;

  }


  .articles_header_news{
    text-align: center;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    height: 46px;
    text-transform: uppercase;
    width:100%;
    margin-top: 6%;
    vertical-align: middle;
    align-items: center;
  }

  .articles_header_kat{
    /* color: #0A6338; */
    text-align: center;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    height: auto;
    margin-top: 9%;
    text-transform: uppercase;
    width:100%;
    vertical-align: middle;
    align-items: center;


  }

  .articles_header{
    background-color: #F2FFFC;
    padding-top: 3%;
    text-align: center;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    height: 70px;
    text-transform: uppercase;
    width:100%;
    vertical-align: middle;
    align-items: center;
    margin-top: 3%;
  }


  .main_subdiv_articles_main_forloop{
    width: auto;
    margin: 0 auto;
  }

  .main_subdiv_our_repair_img{
    width: 115px;
    height: 95px;
    margin-top: 5%;
    object-fit: contain;
  }

  .main_subdiv_our_repair_text{
    color: #012333;
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    text-decoration: underline;
  }

  .main_subdiv_articles_img{
    width: 135px;
    height: 105px;
    margin-top: 5%;
    object-fit: contain;
    margin-left: 5%;
    margin: 0 auto;
  }

  .main_subdiv_articles_text{
    color: #012333;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
   height: auto;
  }


  .ourServicesComputer{
      display: none
  }

  .searchComputer{
      display: none
  }

  .ourServicesComputer{
    display: none
  }

  .our_repairers{
      display: none
  }

}

@media (min-width:450px ){

  .open_gear_box_outside{
    position: fixed;
    height: 100%;
    opacity: 0.5;
    background: black;
    z-index: 1;
    width: 100%;
    left: 0px;
    top:0px;
  }

  .Navigation_large{
    width:100%;
    height:96px;
  }

  .main{
    width:100%;
    margin-top: 3%;
    padding-bottom: 0px;
    background-color: white
  }

  .main_subdiv{
    width:100%;
    text-align: center
  }


  .main_subdiv_WantToRepair_main{
    width:80%;
    margin-left: 14%;
    text-align: center;
    display: flex;
    margin-top: 3%;
    height:auto;
    flex-wrap:wrap;
  }



  .main_subdiv_WantToRepair_main_forloop{
    border-radius: 12px;
    border: 1px solid #D1D1D1;
    background: #FFF;
    box-shadow: 0px 4px 78px 0px rgba(0, 0, 0, 0.06);
    min-width: 282px;
    height: 193px;
    margin-top: 3%;
    margin-left: 4%;
  }

  .main_subdiv_WantToRepair_img{
    width: 90px;
    height: 90px;
    margin-top: 5%;
    margin-left: 2%;
    margin: 0 auto;
  }


  .main_subdiv_WantToRepair_text{
    color: #012333;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
  }

  .main_subdiv_header{
    /* color: #0A6338;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 52px;
    font-style: normal;
    font-weight: 900;
    line-height: 108px; /* 207.692% */
    /* text-transform: uppercase;  */

   font-size: 52px;
   text-align: center;
   color:#0A6338;
   font-weight: 500;
   margin-top: 0%;
   padding-top: 2%
 }

.main_subdiv_header_ingress{
  display:flex;
  color:white;
  text-align: center
}

.main_subdiv_header_ingress_h1{
    /* font-size: 24px;
    width:100%;
    color:#0A6338;
    line-height: 18px;
    letter-spacing:  0px;
    */

    width:100%;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130.5%;
 }

.main_subdiv_bullet_main_mobile{
    display: none;
}

.main_subdiv_bullet_main_computer{
  height:90px;
  margin-top: 0%;
  margin-left:0%;
  text-align: center;
  align-items: center;
  justify-content: center;
  width:100%;
  display:flex;
}

.main_subdiv_bullet_main_forloop{
  color: #012333;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px; /* 150% */
  width: 270px;
  display: flex;
}

  .main_subdiv_bullet_main_icon{
     color:#0A6338;
     margin-top: 1%;
     height: 20px;
     width: 20px;
   }


   .main_subdiv_bullet_main_bullet{
     color: #012333;
     font-size: 18px;
     font-style: normal;
     font-weight: 500;
     line-height: 27px;
     margin-left: 1%;
   }


  .ourServicesMobile{
      display: none
  }

  .searchComputer{
    margin-top: 0%;
  }

  .our_repairers{
    height: 200px;
    width: 100%;
    display:  flex;
    background-color: #F2FFFC;
    /* overflow-x: scroll; */

  }

  .news_div{
      text-align: center;
      align-items: center;
      height: 200px;
      width: 100%;
      display: flex;

  }

  .articles{
      text-align: center;
      align-items: center;
      height: 230px;
      width: 100%;
      display: flex;
      background-color: #F2FFFC ;

  }

  .new_repair_company{
    height: 200px;
    width: 100%;
    text-align: center;
    align-items: center;

  }


  .new_repair_company_titel{
    margin-left:21%;
    text-align:left;
  }

  .new_repair_company_box {
    width:80%;
    text-align:center;
    margin-Left:5%;
    display:flex
  }


  .new_repair_company_input {
    height:30px;
    width:80%;
    margin-Left:20%;
  }


  .main_subdiv_our_repairers_main_forloop{
    width: 220px;
  }
  .main_subdiv_articles_main_forloop{
    width: 220px;
    margin: 0 auto;
  }



  .main_subdiv_our_repair_img{
    width: 115px;
    height: 90px;
    margin-top: 5%;
    object-fit: contain;
  }

  .main_subdiv_our_repair_text{
    color: #012333;
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 23px;
    text-decoration: underline;
  }

  .our_repairers_header{
    /* color: #0A6338; */
    text-align: center;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-size: 28px;
    font-style: normal;
    background-color: #F2FFFC ;
    font-weight: 600;
    height: 46px;
    text-transform: uppercase;
    width:100%;
    vertical-align: middle;
    align-items: center;
    margin-top: 5%;
    padding-top: 1%;
    padding-bottom: 1.5%;
  }

  .articles_header{
    /* color: #0A6338; */
    text-align: center;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    height: 46px;
    text-transform: uppercase;
    width:100%;
    vertical-align: middle;
    align-items: center;
    margin-top: 5%;

  }

  .articles_header_news{
    background-color: #F2FFFC;

    text-align: center;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    height: 46px;
    text-transform: uppercase;
    width:100%;
    vertical-align: middle;
    align-items: center;
    margin-top: 2%;
    padding-top: 1%;
  }


  .articles_header_kat{
    /* color: #0A6338; */
    text-align: center;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    height: 36px;
    margin-top: 7%;
    text-transform: uppercase;
    width:100%;
    vertical-align: middle;
    align-items: center;

  }

  .main_subdiv_articles_img{
    width: 160px;
    height: 140px;
    margin-top: 2%;
    margin-left: 5%;
    object-fit: contain;
  }

  .main_subdiv_articles_text{
    color: #012333;
    font-size: 20px;
    font-style: normal;
    font-weight: 550;
    line-height: 23px;
    text-decoration: underline;
  }


  .searchmobile{
    display: none
  }

}
</style>

<template lang="html">
  <div class="">
    <div :style="{height:'60px', width:'62%', marginLeft:'20%', marginTop:'0%'}">
      <div class="search_class">
        <button type="button"
          :style="{borderStyle:'none',borderRadius:'3px 0px 0px 3px',
          backgroundColor:'#0A6338', boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
          width:'220px',color:'white', fontSize:'18px'}"  v-if="!lan_chosen" @click="open_lan()" name="button">
           Vilket län bor du i?   <b-icon  icon="caret-down" ></b-icon>
         </button>
        <button type="button"  :style="{borderStyle:'none',boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)', fontColor:'#8A8A8A',borderRadius:'3px 0px 0px 3px', backgroundColor:'white',fontSize:'18px',color:'#0A6338', width:'220px' }"
            v-if="lan_chosen" name="button"   @click="open_lan()">{{this.lan_name}}
          <b-icon  icon="caret-down" ></b-icon>
        </button>
              <input v-model="search_item" type="text" id="1" name="input" v-on:keyup.enter="onEnter"
                v-on:keyup="get_company_search(search_item)"
                :style="{color:'black',marginLeft:'0.9%',boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)' ,paddingLeft:'1%',border:'none',
                borderRadius:'0px 3px 3px 0px',  width:'88%',
                        height:'100%',fontSize:'18px',marginTop:'0%'}"
                placeholder="Vad behöver du laga/fixa? " value="">
              <b-icon :style="{ width:'15px', marginLeft:'60%',marginTop:'1.2%', position:'absolute',cursor:'pointer'}"
              icon="x-lg"     @click="open_search()"  v-if="this.search_item_flag==1"   />


      </div>
      <div v-if= "this.län_opened" :style="{height:'170px',border:0, position:'absolute',
            borderWidth:'0', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',
            borderRadius:'0px 3px 3px 0px',marginTop:'0%',overflowY:'auto',
            borderColor:'black',
            opacity:'100%',zIndex:13, width:'15%',marginTop:'0.5%',
             backgroundColor:'white',marginLeft:'0%'}">

      <div  v-for="(län,idx) in län_list" :style="{marginLeft:'4%'}"
      :key="idx" @click="choose_län_func(län.id,län.lan)" >
        <div :style="{height:'26px',fontSize:'18px', display:'flex',marginTop:'1%'}" >
          <div :style="{marginLeft:'1%',marginTop:'0.5%', color:'black'}">
            {{län.lan}}
          </div>
        </div>
      </div>
      </div>
    </div>

    <div  v-if="this.search_item_flag==1"
    :style="{height:'180px',border:0, position:'absolute',borderWidth:'0',  boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',borderRadius:'26px',marginTop:'0%',  opacity:'100%',zIndex:13, backgroundColor:'white',width:'47%', marginLeft:'35%'}">
    <div v-if="search_list.length ==0 ">
      Tyvärr hittade vi inga resultat denna gången men skicka gärna en förfrågan så ska vi försöka hjälpa dig
    </div>
     <div v-if="search_list.length !=0 " :style="{height:'180px',overflowY:'auto'}" >
        <div v-for="(search,idx) in search_list" :key="idx"
             :style="{marginLeft:'1%',textAlign:'left',  width:'99%',height:'22px', fontSize:'20px', fontWeight:'bold', marginTop:'1%'}">
          <div :style="{width:'100%',height:'22px', display:'flex',marginTop:'1%'}"  @click="to_category(search.id,search.category)"   >
            <div :style="{marginLeft:'1%',marginTop:'0.5%',fontSize:'18px',fontWeight:500, color:'black'}">
              {{search.category}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import router from '../router/index'
import axios from 'axios';
import {API_URL} from '../../config.js'
import {bus} from '../main'

export default {
  data:function(){
    return{
      lan_name:"",
      lan_id:0,
      lan_chosen:false,
      län_list:[],
      län_opened:false,
      category_id:0,
      category_name:'',
      search_item_flag:0,
      search_item:'',
      search_list:'',

    }
  },
  mounted(){
    this.län_list =[
      {'id':1, 'lan': 'Blekinge län'   },
      {'id':2, 'lan': 'Dalarnas län'   },
      {'id':3, 'lan': 'Gotlands län'   },
      {'id':4, 'lan': 'Gävleborgs län'   },
      {'id':5, 'lan': 'Hallands län'   },
      {'id':6, 'lan': 'Jämtlands län'   },
      {'id':7, 'lan': 'Jönköpings län'   },
      {'id':8, 'lan': 'Kalmar län'   },
      {'id':9, 'lan': 'Kronobergs län'   },
      {'id':10, 'lan': 'Norrbottens län'   },
      {'id':11, 'lan': 'Skåne län'   },
      {'id':12, 'lan':  'Stockholms län'  },
      {'id':13, 'lan':  'Södermanlands län'  },
      {'id':14, 'lan':  'Uppsala län'  },
      {'id':15, 'lan':  'Värmlands län'  },
      {'id':16, 'lan':  'Västerbottens län'  },
      {'id':17, 'lan':  'Västernorrlands län'  },
      {'id':18, 'lan':  'Västmanlands län'  },
      {'id':19, 'lan':  'Västra Götalands län'  },
      {'id':20, 'lan':  'Örebro län'  },
      {'id':21, 'lan':  'Östergötlands län'  }
    ]

      if(this.$store.state.lan_object ==undefined){
        this.lan_name =''
        this.lan_id =0
        this.lan_chosen = false
      }
      else {
        this.lan_name =this.$store.state.lan_object.lan
        this.lan_id = this.$store.state.lan_object.lan_id
        this.lan_chosen = this.$store.state.lan_object.lan_chosen

      }


  },
  methods:{
    open_lan(){
        if(this.län_opened){
          this.län_opened = false
        }
        else{
          this.län_opened = true
        }
    },
    get_company_search(){
       if(this.search_item.length >0){
        this.search_item_flag =1
        var self = this
        axios({
            method:'get',
            url: API_URL+ '/search_categories'+'?search_item='+this.search_item+'&lan_id='+this.lan_id,
          })
          .then(function(response){
              self.search_list=response.data
          })
      }else {
        this.search_item_flag =0
      }
    },
    to_category(category_id,category_name){
        this.$forceUpdate()
        axios({
          method:'post',
          url: API_URL+ '/click_category',
          data:{'category_id':category_id, lan_id:this.lan_id}
        })
        .then(function(){
         })
        this.search_item=category_name
         this.category_id=category_id
         this.category_name=category_name
         this.search_item_flag=0


        router.push({ path:"result_page", query:{category_id:this.category_id, from_page:'category', category_name:this.category_name} }).catch(()=>{})
    },
    get_results(){
      router.push({ path:"result_page", query:{category_id:this.category_id, from_page:'category', category_name:this.category_name, lan_id:this.lan_id,lan:this.lan_name } }).catch(()=>{})
      //router.push({ path:"result" ,query:{ from_page:'search_multiple_keywords',area_id: this.chosen_area_id, chosen_employees_id:this.chosen_employees_id}})
    },

    open_search(){
      this.search_item =''
      this.search_item_flag=0
    },
    to_company(id){
      this.$forceUpdate()
      router.push({ path:"company_page", query:{id:id} })
    },
    choose_län_func(lan_id,lan_name){
      this.lan_id=lan_id
      this.lan_name =lan_name
      this.lan_chosen = true
      this.län_opened=false
      var lan_filter_start = true
      this.$store.commit('set_lan', {"lan_id":this.lan_id,"lan":this.lan_name, "lan_chosen": this.lan_chosen, "lan_filter_start":lan_filter_start})
      bus.$emit("remove_filter");
      // axios({
      //   method:'post',
      //   url: API_URL+ '/click_lan',
      //   data:{'lan_id':lan_id}
      // })
      // .then(function(){
      //  })

    },

  }

}
</script>

<style lang="css" scoped>

.search_class{
  width: 100%;
  height: 52px;
  margin-top: 2%;
  /* background: lightgrey; */
  /* rgba(196, 196, 196, 0.32); */
  border-radius: 3px;
  display: flex;
  /* border-style: solid; */
}


</style>
